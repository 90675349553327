import FileSaver from "file-saver";

function createFile(base64Data) {
  var base64str = "";
  if (base64Data.includes(";base64,")) {
    base64str = base64Data.split(";base64,")[1];
  } else {
    base64str = base64Data;
  }
  var binary = window.atob(base64str.replace(/\s/g, ""));
  var len = binary.length;
  var buffer = new ArrayBuffer(len);
  var array = new Uint8Array(buffer);
  for (var i = 0; i < len; i++) {
    array[i] = binary.charCodeAt(i);
  }

  return new Blob([array], {
    type: "application/pdf"
  });
}

export function downloadFile(base64Data, filename) {
  var file = createFile(base64Data);

  let mobileSafariAgent =
    navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
    navigator.userAgent.match(/AppleWebKit/);

  if (mobileSafariAgent == null) {
    FileSaver.saveAs(file, filename);
  } else {
    var fileURL = URL.createObjectURL(file);
    var link = document.createElement("a");
    link.href = fileURL;
    link.download = filename;
    link.click();
  }
}

export function openFileInTab(base64Data) {
  var file = createFile(base64Data);
  var fileUrl = URL.createObjectURL(file);
  window.open(fileUrl);
}
